<template>
    <div>
        <v-dialog :persistent="revokeLoading === true" v-if="showRevokeButton === true" v-model="revokeDialog" max-width="500">
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            small
                            color="primary"
                            icon
                            v-on="{ ...tooltip, ...dialog }"
                            v-if="
                                hierarchicalLevel === 'organization' ||
                                    hierarchicalLevel === 'space' ||
                                    (reason && hierarchicalLevel === 'instance' && reason.toUpperCase() === 'EXPLICIT') ||
                                    (hierarchicalLevel === 'instance' && spaceAdmin === false)
                            "
                        >
                            <v-icon small>clear</v-icon>
                        </v-btn>
                        <v-tooltip v-else-if="hierarchicalLevel === 'instance' && reason.toUpperCase() !== 'EXPLICIT'" left>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon small>clear</v-icon></v-btn>
                            </template>
                            <span
                                >You cannot revoke
                                {{
                                    reason === roleTypes.SPACE_ADMIN
                                        ? ' a space admin'
                                        : reason === roleTypes.ORG_MANAGER
                                        ? ' an organization manager'
                                        : reason + ' role.'
                                }}.</span
                            >
                        </v-tooltip>
                    </template>
                    <span>Revoke User Access</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title>
                    <div class="title primary--text text-uppercase d-flex align-center">
                        <v-icon class="mr-1">clear</v-icon>revoke from {{ hierarchicalLevel }}
                    </div>
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text>
                    <div class="d-flex flex-column pa-2 mt-2 mb-2 info-tile">
                        <span
                            >User: <span class="font-weight-bold">{{ userName }}</span></span
                        >
                        <span v-if="hierarchicalLevel === 'instance'"
                            >Instance: <span class="font-weight-bold">{{ instanceName }}</span></span
                        >
                        <span v-else-if="hierarchicalLevel === 'space'"
                            >Space: <span class="font-weight-bold">{{ spaceName }}</span></span
                        >
                        <span v-else-if="hierarchicalLevel === 'organization'"
                            >Organization: <span class="font-weight-bold">{{ orgName }}</span></span
                        >
                        <span
                            >Role: <span class="font-weight-bold">{{ role }}</span></span
                        >
                    </div>
                    <span v-if="hierarchicalLevel === 'instance'"
                        >By revoking the user instance role, the user will no more be able to see or work with the instannce.</span
                    >
                    <span v-if="hierarchicalLevel === 'space'"
                        >By revoking the user space role, the user will lose the administrator rights for this space but will keep the instance-level roles if
                        any.</span
                    >
                    <span v-if="hierarchicalLevel === 'organization'"
                        >By revoking the user organization role, the user will no more be able to see or work with any of the spaces and instances in the
                        organization.</span
                    >
                    <v-checkbox v-if="hierarchicalLevel === 'space'" v-model="revokeSpaceChildren" label="Revoke all instance roles for this user."
                        >Revoke</v-checkbox
                    >
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="revokeLoading === true" color="primary" class="mr-1" text @click="revokeDialog = false">Cancel</v-btn>
                    <v-btn :loading="revokeLoading === true" text color="error" @click="confirmAction('revokeAccess', ucid)">Revoke</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :persistent="activationLoading === true"
            v-model="deactivateDialog"
            max-width="500"
            v-if="userInfo.is_sysadmin === true && isActiveUser === true && showDeactivateActivateButton === true"
        >
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small color="error" text icon v-on="{ ...tooltip, ...dialog }">
                            <v-icon small>mdi-account-off</v-icon>
                        </v-btn>
                    </template>
                    <span>Deactivate User</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title>
                    <div class="title primary--text text-uppercase d-flex align-center"><v-icon class="mr-1">mdi-account-off</v-icon>user deactivation</div>
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text
                    >Are you sure you want to deactivate the account of the user <span class="font-weight-bold">{{ userName }}</span
                    >?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="activationLoading === true" color="primary" class="mr-1" text @click="deactivateDialog = false">Cancel</v-btn>
                    <v-btn :loading="activationLoading === true" text color="error" @click="confirmAction('deactivateUser', uid)">deactivate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :persistent="activationLoading === true"
            v-model="activateDialog"
            max-width="500"
            v-if="userInfo.is_sysadmin === true && isActiveUser === false && showDeactivateActivateButton === true"
        >
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small color="success" text icon v-on="{ ...tooltip, ...dialog }">
                            <v-icon small>mdi-account-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Activate User</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title>
                    <div class="title primary--text text-uppercase d-flex align-center"><v-icon class="mr-1">mdi-account-check</v-icon>user activation</div>
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text
                    >Are you sure you want to activate the account of the user <span class="font-weight-bold">{{ userName }}</span
                    >?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="activationLoading === true" color="primary" class="mr-1" text @click="activateDialog = false">cancel</v-btn>
                    <v-btn :loading="activationLoading === true" text color="error" @click="confirmAction('activateUser', uid)">activate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'

export default {
    name: 'UserActions',
    mixins: [enumsData],
    props: {
        uid: String,
        ucid: String,
        userName: String,
        hierarchicalLevel: String,
        isActiveUser: Boolean,
        role: String,
        reason: {
            type: String,
            default: null
        },
        spaceAdmin: {
            type: Boolean,
            default: null
        },
        showRevokeButton: {
            type: Boolean,
            default: true
        },
        showDeactivateActivateButton: {
            type: Boolean,
            default: true
        },
        instanceName: {
            type: String,
            default: null
        },
        instanceId: {
            type: String,
            default: null
        },
        spaceName: {
            type: String,
            default: null
        },
        orgName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            revokeDialog: false,
            deactivateDialog: false,
            activateDialog: false,
            revokeLoading: false,
            activationLoading: false,
            revokeSpaceChildren: false
        }
    },
    methods: {
        confirmAction: function(actionType, userId) {
            var apiURL
            if (actionType === 'revokeAccess') {
                this.$data.revokeLoading = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Revoking access, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                if (this.$props.hierarchicalLevel === 'organization') {
                    apiURL = '/orgs/' + this.$route.params.oid + `/remove_conn/${userId}`
                } else if (this.$props.hierarchicalLevel === 'space' && this.$data.revokeSpaceChildren === true) {
                    apiURL = '/spaces/' + this.$route.params.sid + `/remove_conn/${userId}`
                } else if (this.$props.hierarchicalLevel === 'space' && this.$data.revokeSpaceChildren === false) {
                    apiURL = '/spaces/' + this.$route.params.sid + `/revoke_role_from_conn/${userId}`
                } else if (this.$props.hierarchicalLevel === 'instance') {
                    apiURL = '/instances/' + this.instanceId + `/revoke_role_from_conn/${userId}`
                }
            } else if (actionType === 'activateUser') {
                this.$data.activationLoading = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Activating user account, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                apiURL = `/users/${userId}/activate`
            } else if (actionType === 'deactivateUser') {
                this.$data.activationLoading = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Deactivating user account, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                apiURL = `/users/${userId}/deactivate`
            }
            actionType === 'revokeAccess'
                ? this.$axios
                      .delete(apiURL)
                      .then(response => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText: 'User access revoked successfully',
                              snackBarTimeout: 5000,
                              snackBarIcon: 'check_circle'
                          })
                          this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
                          if (this.$props.hierarchicalLevel === 'space' || this.$props.hierarchicalLevel === 'instance') {
                              this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                              this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                          }
                      })
                      .catch(() => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText: 'An error has occurred',
                              snackBarTimeout: 10000,
                              snackBarIcon: 'error'
                          })
                      })
                      .finally(() => {
                          this.$data.revokeLoading = false
                          this.$data.revokeDialog = false
                      })
                : this.$axios
                      .put(apiURL)
                      .then(response => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText:
                                  actionType === 'activateUser'
                                      ? 'User account activated successfully!'
                                      : actionType === 'deactivateUser'
                                      ? 'User account deactivated successfully'
                                      : 'Operation finished successfully!',
                              snackBarTimeout: 5000,
                              snackBarIcon: 'check_circle'
                          })
                          this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
                          if (this.$props.hierarchicalLevel === 'space' || this.$props.hierarchicalLevel === 'instance') {
                              this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                              this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                          }
                      })
                      .catch(() => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText: 'An error has occurred',
                              snackBarTimeout: 10000,
                              snackBarIcon: 'error'
                          })
                      })
                      .finally(() => {
                          this.$data.activationLoading = false
                          this.$data.activateDialog = false
                          this.$data.deactivateDialog = false
                      })
        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>
