var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showRevokeButton === true
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.revokeLoading === true,
                "max-width": "500"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var dialog = ref.on
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var tooltip = ref.on
                                    return [
                                      _vm.hierarchicalLevel ===
                                        "organization" ||
                                      _vm.hierarchicalLevel === "space" ||
                                      (_vm.reason &&
                                        _vm.hierarchicalLevel === "instance" &&
                                        _vm.reason.toUpperCase() ===
                                          "EXPLICIT") ||
                                      (_vm.hierarchicalLevel === "instance" &&
                                        _vm.spaceAdmin === false)
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "primary",
                                                  icon: ""
                                                }
                                              },
                                              Object.assign({}, tooltip, dialog)
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("clear")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm.hierarchicalLevel ===
                                            "instance" &&
                                          _vm.reason.toUpperCase() !==
                                            "EXPLICIT"
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { left: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [_vm._v("clear")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "You cannot revoke " +
                                                    _vm._s(
                                                      _vm.reason ===
                                                        _vm.roleTypes
                                                          .SPACE_ADMIN
                                                        ? " a space admin"
                                                        : _vm.reason ===
                                                          _vm.roleTypes
                                                            .ORG_MANAGER
                                                        ? " an organization manager"
                                                        : _vm.reason + " role."
                                                    ) +
                                                    "."
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Revoke User Access")])]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3448456314
              ),
              model: {
                value: _vm.revokeDialog,
                callback: function($$v) {
                  _vm.revokeDialog = $$v
                },
                expression: "revokeDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title primary--text text-uppercase d-flex align-center"
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("clear")
                        ]),
                        _vm._v(
                          "revoke from " + _vm._s(_vm.hierarchicalLevel) + " "
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column pa-2 mt-2 mb-2 info-tile"
                        },
                        [
                          _c("span", [
                            _vm._v("User: "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.userName))
                            ])
                          ]),
                          _vm.hierarchicalLevel === "instance"
                            ? _c("span", [
                                _vm._v("Instance: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.instanceName))]
                                )
                              ])
                            : _vm.hierarchicalLevel === "space"
                            ? _c("span", [
                                _vm._v("Space: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.spaceName))]
                                )
                              ])
                            : _vm.hierarchicalLevel === "organization"
                            ? _c("span", [
                                _vm._v("Organization: "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.orgName))]
                                )
                              ])
                            : _vm._e(),
                          _c("span", [
                            _vm._v("Role: "),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.role))
                            ])
                          ])
                        ]
                      ),
                      _vm.hierarchicalLevel === "instance"
                        ? _c("span", [
                            _vm._v(
                              "By revoking the user instance role, the user will no more be able to see or work with the instannce."
                            )
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel === "space"
                        ? _c("span", [
                            _vm._v(
                              "By revoking the user space role, the user will lose the administrator rights for this space but will keep the instance-level roles if any."
                            )
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel === "organization"
                        ? _c("span", [
                            _vm._v(
                              "By revoking the user organization role, the user will no more be able to see or work with any of the spaces and instances in the organization."
                            )
                          ])
                        : _vm._e(),
                      _vm.hierarchicalLevel === "space"
                        ? _c(
                            "v-checkbox",
                            {
                              attrs: {
                                label:
                                  "Revoke all instance roles for this user."
                              },
                              model: {
                                value: _vm.revokeSpaceChildren,
                                callback: function($$v) {
                                  _vm.revokeSpaceChildren = $$v
                                },
                                expression: "revokeSpaceChildren"
                              }
                            },
                            [_vm._v("Revoke")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.revokeLoading === true,
                            color: "primary",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.revokeDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.revokeLoading === true,
                            text: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmAction("revokeAccess", _vm.ucid)
                            }
                          }
                        },
                        [_vm._v("Revoke")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userInfo.is_sysadmin === true &&
      _vm.isActiveUser === true &&
      _vm.showDeactivateActivateButton === true
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.activationLoading === true,
                "max-width": "500"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var dialog = ref.on
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var tooltip = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "error",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          Object.assign({}, tooltip, dialog)
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-account-off")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Deactivate User")])]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1161924684
              ),
              model: {
                value: _vm.deactivateDialog,
                callback: function($$v) {
                  _vm.deactivateDialog = $$v
                },
                expression: "deactivateDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title primary--text text-uppercase d-flex align-center"
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-account-off")
                        ]),
                        _vm._v("user deactivation")
                      ],
                      1
                    )
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c("v-card-text", [
                    _vm._v(
                      "Are you sure you want to deactivate the account of the user "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.userName))
                    ]),
                    _vm._v("?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.activationLoading === true,
                            color: "primary",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.deactivateDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.activationLoading === true,
                            text: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmAction(
                                "deactivateUser",
                                _vm.uid
                              )
                            }
                          }
                        },
                        [_vm._v("deactivate")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.userInfo.is_sysadmin === true &&
      _vm.isActiveUser === false &&
      _vm.showDeactivateActivateButton === true
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.activationLoading === true,
                "max-width": "500"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var dialog = ref.on
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var tooltip = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "success",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          Object.assign({}, tooltip, dialog)
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-account-check")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Activate User")])]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3522065599
              ),
              model: {
                value: _vm.activateDialog,
                callback: function($$v) {
                  _vm.activateDialog = $$v
                },
                expression: "activateDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "title primary--text text-uppercase d-flex align-center"
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-account-check")
                        ]),
                        _vm._v("user activation")
                      ],
                      1
                    )
                  ]),
                  _c("v-divider", { staticClass: "mb-1" }),
                  _c("v-card-text", [
                    _vm._v(
                      "Are you sure you want to activate the account of the user "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.userName))
                    ]),
                    _vm._v("?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            disabled: _vm.activationLoading === true,
                            color: "primary",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.activateDialog = false
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.activationLoading === true,
                            text: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmAction("activateUser", _vm.uid)
                            }
                          }
                        },
                        [_vm._v("activate")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }